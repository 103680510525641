<template>
  <div style='margin-top: 10px'>
    <div style='display: flex;flex-direction: row;justify-content: space-between'>
      <SearchTool ref='refSearchTool' :live-type='1' style='width: 80%' @handleQuery='handleQuery'></SearchTool>
      <export-link :lint-title='`导出`' ref='refExportLink'
                   :can-export="userPermissions.indexOf('live_data_export') !== -1"
                   @export='exportLiveData' />

    </div>
    <!--    表格-->
    <LiveDataPlatformTable ref='refLiveDataPlatformTable' :groupId='groupId'
                           :liveType='1' />
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import ArtistSearch from '@/components/artist/ArtistSearch'
import ExportLink from '@/components/export/ExportLink'
import LiveDataPlatformTable from './LiveDataPlatformTable'
import SearchTool from '@/pages/live_data/SearchTool'

export default {
  name: 'LiveDataList',
  components: {
    SearchTool,
    ApeTable,
    ApeDrawer,
    PlatformsRadioGroup,
    ArtistSearch,
    LiveDataPlatformTable,
    ExportLink
  },
  data() {
    return {
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      checkedRadio: null,
      dayRange: [
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
        { label: '近90天', value: 90 }
      ],
      ym: null,
      searchCondition: {
        platform_code: null,
        time_value: [],
        artist_id: null,
        special_venue: null,
        special_type: null
      },
      fileTitle: '',
      orderSort: { live_time: 'desc', id: 'desc' },//默认排序规则
      GroupDeptOption: [],
      groupId: ''
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  created() {

  },
  mounted() {

  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 3) {
        // this.defaultDate()
      }
    }
  },
  methods: {

    clearCondition() {
      this.searchCondition = {
        nickname: null,
        group_id: null,
        sign_status: 'Y',
        platforms: [],
        special_venue: null
      }
    },

    handleQuery(searchCondition) {
      this.searchCondition = searchCondition
      this.$refs['refLiveDataPlatformTable'].show(this.searchCondition)
    },
    //导出表格
    async exportLiveData() {
      try {
        let searchCondition = this.handleSearchCondition()
        Object.assign(searchCondition, { order: this.orderSort }, { live_type: 1 })
        let response = await this.$api.exportLiveInfos(searchCondition)
        let name = '直播(达播)数据'

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      return this.$refs['refSearchTool'].handleSearchCondition()
      //
      // let condition = {}
      // if (this.searchCondition.artist_id) {
      //   condition.artist_id = this.searchCondition.artist_id
      // }
      // if (this.searchCondition.group_ids && this.searchCondition.group_ids.length > 0) {
      //   condition.group_ids = this.searchCondition.group_ids
      // }
      // if (this.searchCondition.platform_code) {
      //   condition.platform_code = this.searchCondition.platform_code
      // }
      //
      // if (this.searchCondition.time_value) {
      //   condition.start_time = this.searchCondition.time_value[0]
      //   condition.end_time = this.searchCondition.time_value[1]
      // }
      // if (this.searchCondition.special_venue) {
      //   condition.special_venue = this.searchCondition.special_venue
      // }
      // if (this.searchCondition.special_type) {
      //   condition.special_type = this.searchCondition.special_type
      // }
      // return condition
    }
  }
}
</script>

<style scoped>

</style>
